import { message, notification } from "antd";
import { useEffect, useState } from "react";
import { SetorType } from "../../types/SetorType";
import { ComumType } from "../../types/ComumType";
import { UserType } from "../../types/UserType";
import { AuthContext } from "./AuthContext";
import { Login } from "../../pages/Login";
import { useApiComum } from "../../hooks/useApiComum";
import { useApiAuthentication } from "../../hooks/useApiAuthentication";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<UserType | null>(null);
  const [setor, setSetor] = useState<SetorType | null>(null);
  const [comuns, setComuns] = useState<Array<ComumType> | null>(null);
  const [comum_sel, setComumSel] = useState<ComumType | null>(null);
  const [perfil, setPerfil] = useState<[] | null>([]);
  const apiComum = useApiComum();
  const apiAuthenticate = useApiAuthentication();

  useEffect(() => {
    //if(children != Login()){
    if (
      localStorage.getItem("authToken") != undefined &&
      localStorage.getItem("authToken") != null
    ) {
      validateToken();
    }
    //}
  }, []);

  const validateToken = async () => {
    let storageDate = localStorage.getItem("authToken");
    if (storageDate) {
      const res = await apiAuthenticate.validateToken(storageDate);
      const data = res.data;
      if (data.user) {
        setUser(data.user);
        const params = data.parameters;
        setSetor(params.setor);
        setComuns(params.comuns);
        params.comum_sel
          ? setComumSel(params.comum_sel)
          : setComumSel(params.comum);
        setToken(data.token);
        setPerfil(params.perfil);
      }
    }
  };

  const signin = async (cpf: string, password: string) => {
    const response = await apiAuthenticate.signin(cpf, password);
    const data = response.data;
    if (data != false) {
      if (data.user && data.token) {
        setUser(data.user);
        const params = data.parameters;
        setSetor(params.setor);
        setComuns(params.comuns);
        params.comum_sel
          ? setComumSel(params.comum_sel)
          : setComumSel(params.comum);
        setToken(data.token);
        setPerfil(params.perfil);
        return data.user;
      }
    }
    return null;
  };

  const signout = async () => {
    await apiAuthenticate.logout(localStorage.getItem("authToken")!);
    setUser(null);
    setToken("");
    setComumSel(null);
    setSetor(null);
    setComuns(null);
    setPerfil(null);
    //caso nao funcione faz assim reload
    //window.location.href = window.location.href;
  };

  const setToken = (token: string) => {
    localStorage.setItem("authToken", token);
  };

  const setComum = async (comum: number | null) => {
    //if (comum) {
    await apiComum.setComum(comum).then((response: any) => {
      console.log(response);
      const data = response.data;
      if (data != false) {
        if (data.user && data.token) {
          setUser(data.user);
          const params = data.parameters;
          setSetor(params.comum);
          setComuns(params.comuns);
          setComumSel(params.comum_sel);
          setPerfil(params.perfil);
          setToken(data.token);
          notification.warning({
            message: response.message,
            duration: 10,
          });
          //return true;
        } else {
          notification.error({ message: "Erro ao selecionar" });
        }
      } else {
        return false;
      }
      const selCom = comuns?.find((c) => c.id === comum);
      setComumSel(selCom!);
    });
    /*} else {
      setComumSel(null);
    }*/
    return true;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setor,
        comuns,
        comum_sel,
        signin,
        signout,
        setComum,
        perfil,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
