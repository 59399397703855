import {
  ApiFilled,
  CheckOutlined,
  FolderAddOutlined,
  PlusOutlined,
  ScanOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  FloatButton,
  Form,
  Input,
  List,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { Option } from "antd/es/mentions";
import { useEffect, useRef, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useApi } from "../../hooks/useApi";
import Webcam from "react-webcam";
import { ColumnsType } from "antd/es/table";
import { CardFI } from "../../components/CardFI";

interface DataType {
  key: string;
  nome: string;
  tipo: string;
  comum: string;
  comum_id: string;
  cargo: string;
  cargo_id: string;
  validade: string;
  ativo: boolean;
}

interface DataTypeCheckIn {
  id: string;
  pessoa_id: string;
  evento_id: string;
  checkin: string;
  pessoa_nome: string;
  pessoa_comum: string;
  pessoa_cargo: string;
}

export const CheckIn = () => {
  const api = useApi();
  const [evento, setEvento] = useState({ id: "", nome: "" });
  const [data, setData] = useState("Aguardando");
  const [registros, setRegistros] = useState([]);
  const [openModalQrCode, setOpenModalQrCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageLoad, setMessageLoad] = useState("Carregando...");
  const [delay, setDelay] = useState(0);
  const reader = useRef(null);
  const [eventos, setEventos] = useState([]);
  const [listCheck, setListCheck] = useState([]);
  const [eventoSel, setEventoSel] = useState("");
  const [openCheckinNome, setOpenCheckinNome] = useState(false);
  const [membros, setMembros] = useState([]);
  const [comuns, setComuns] = useState([]);

  //Lista Eventos
  const columnsCheckin: ColumnsType<DataTypeCheckIn> = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      render: (_, record) => {
        return (
          <>
            <Row style={{ fontSize: 14 }}>
              <strong>{record.pessoa_nome}</strong>:&nbsp;
              {record.pessoa_cargo}
            </Row>
            <Row style={{ fontSize: 12, color: "#414141" }}>
              Comum: {record.pessoa_comum}
            </Row>
          </>
        );
      },
    },
    {
      title: "CheckIn",
      dataIndex: "checkin",
      key: "checkin",
    },
    /*{
      title: "Ações",
      key: "action",
      render: (_, record) => {
        return (
          <>
            <Space size="middle">
              <Button
                type="link"
                size="small"
                onClick={() => checkIn(record.id)}
                icon={<CheckOutlined />}
                //disabled={!record.ativo}
              />
            </Space>
          </>
        );
      },
      width: "20%",
    },*/
  ];

  //Lista
  const columns: ColumnsType<DataType> = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      render: (_, record) => {
        return (
          <>
            <Row style={{ fontSize: 14 }}>
              {record.tipo}:&nbsp;<strong>{record.nome}</strong>
            </Row>
            <Row style={{ fontSize: 12, color: "#414141" }}>
              Comum: {record.comum}
            </Row>
          </>
        );
      },
    },
    {
      title: "Cargo / Função",
      dataIndex: "cargo",
      key: "cargo",
      width: "30%",
    },
    {
      title: "Ações",
      key: "action",
      render: (_, record) => {
        return (
          <>
            <Space size="middle">
              <Button
                type="link"
                size="small"
                onClick={() => checkIn(record.key)}
                icon={<CheckOutlined />}
                disabled={!record.ativo}
              />
            </Space>
          </>
        );
      },
      width: "20%",
    },
  ];

  const handleScan = (data: any) => {
    setData(data);
  };
  const handleError = (err: any) => {
    console.error(err);
  };
  /*const getRegistrosTime = () => {
        const timeout = setTimeout(() => {
            getRegistrosTime();
        }, 10000);
    }*/
  const handleOk = () => {
    if (eventoSel) {
      setDelay(100);
      setOpenModalQrCode(true);
    } else {
      message.warning({ content: "Você esqueceu de selecionar um evento!" });
    }
  };

  const checkinPorNome = () => {
    if (eventoSel) {
      getComuns().finally(() => {
        setOpenCheckinNome(true);
      });
    } else {
      message.warning({ content: "Você esqueceu de selecionar um evento!" });
    }
  };

  const handleCancel = () => {
    setDelay(0);
    setOpenModalQrCode(false);
    closeCam();
  };

  const getEventos = async () => {
    await api.getEventos(null).then((res) => {
      if (res) {
        const option: any = [];
        res.forEach((item: any) => {
          option.push({
            value: item.id,
            label: item.data + " " + item.hora + " - " + item.nome,
          });
        });
        setEventos(option);
      } else {
        message.error("Não há comuns cadastrados");
        setEventos([]);
      }
    });
  };

  const getCheckIns = async (id: string) => {
    setEventoSel(id);
    await api.getCheckIns(id).then((res) => {
      if (res) {
        setListCheck(res);
      } else {
        setListCheck([]);
      }
    });
  };

  const checkIn = async (target: string) => {
    setLoading(true);
    setMessageLoad("Fazendo checkin no evento, aguarde...");
    if (eventoSel != "") {
      const date = new Date();
      await api.checkIn(target, eventoSel, date).then((res) => {
        getCheckIns(eventoSel);
        closeCam();
        setOpenModalQrCode(false);
        notification.success({
          message: "Status do CheckIn",
          description: res,
        });
      });
    } else {
      closeCam();
      setOpenModalQrCode(false);
      notification.error({
        message: "Evento inválido!",
        description:
          "É necessário a seleção de um evento para fazer o checkIn deste participante.",
      });
    }
    setLoading(false);
    setMessageLoad("");
  };

  const closeCam = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    stream.getTracks().forEach(function (track) {
      track.stop();
      track.enabled = false;
    });
  };

  const getPessoas = async (e: number) => {
    setLoading(true);
    setMessageLoad("Buscando irmãos, aguarde...");
    // setComumSel(e);
    await api.getPessoas(e).then((res) => {
      const newData: any = [];
      res.forEach((item: any) => {
        const m: DataType = {
          key: item.id,
          nome: item.nome,
          comum: item.comum,
          comum_id: item.comum_id,
          cargo: item.cargo,
          cargo_id: item.cargo_id,
          tipo: item.tipo,
          validade: item.validade,
          ativo: item.ativo,
        };
        newData.push(m);
      });
      setMembros(newData);
    });
    setLoading(false);
    setMessageLoad("");
  };

  const onCloseCheckinPonNome = () => {
    setOpenCheckinNome(false);
  };

  const getComuns = async () => {
    setLoading(true);
    setMessageLoad("Buscando comuns, aguarde...");
    await api.getComuns().then((res) => {
      if (res) {
        const option: any = [];
        res.forEach((item: any) => {
          option.push({
            value: item.id,
            label: item.codigo + " - " + item.nome,
          });
        });
        setComuns(option);
      } else {
        message.error("Não há comuns cadastrados");
        setComuns([]);
      }
    });
    setLoading(false);
    setMessageLoad("");
  };

  useEffect(() => {
    //getRegistrosTime();
    getEventos();
    //closeCam();
  }, []);

  return (
    <Spin spinning={loading} tip={messageLoad} style={{ zIndex: 9999 }}>
      <CardFI
        id="checkin"
        title="CheckIn de Evento"
        size="80vw"
        display="block"
      >
        <>
          <Row
            style={{
              width: "100%",
              padding: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: -20,
            }}
          >
            <span
              style={{
                paddingBottom: 5,
                color: "#ffffff",
                width: "99%",
                fontWeight: 500,
              }}
            >
              Escolha um evento ativo
            </span>
            <Select
              style={{ width: "99%" }}
              options={eventos}
              onChange={getCheckIns}
            />
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: 10,
              color: "#fff",
              fontSize: 18,
              fontFamily: "Roboto",
            }}
          >
            <Table
              dataSource={listCheck}
              columns={columnsCheckin}
              scroll={{ y: "33vh" }}
              size="small"
              pagination={false}
            />
            <Divider style={{ marginTop: -5 }} />
            <Row style={{ marginTop: -5 }}>
              <Space style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  icon={<ScanOutlined />}
                  onClick={() => {
                    handleOk();
                  }}
                >
                  Ler QRCode
                </Button>
                <Button
                  type="default"
                  icon={<UserOutlined />}
                  onClick={() => {
                    checkinPorNome();
                  }}
                >
                  CheckIn pelo Nome
                </Button>
              </Space>
            </Row>
          </Row>
        </>
      </CardFI>
      <Modal
        title={"Aproxime o QrCode"}
        open={openModalQrCode}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={9999}
        footer
      >
        <div
          style={{
            height: 300,
            width: 300,
            borderRadius: "8px",
            backgroundColor: "#000000",
          }}
        >
          {openModalQrCode && (
            <QrReader
              scanDelay={100}
              onResult={async (scan: any, error) => {
                if (scan) {
                  setOpenModalQrCode(false);
                  const reg = JSON.parse(scan?.text);
                  checkIn(reg.codigo);
                }
                /*if(!!error){
                                console.info(error);
                            }*/
              }}
              constraints={{ facingMode: "enviroment" }}
            />
          )}
        </div>
      </Modal>
      <Modal
        title={"Entrar por nome"}
        open={openCheckinNome}
        footer
        onCancel={onCloseCheckinPonNome}
        onOk={onCloseCheckinPonNome}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: 10,
          }}
        >
          <Row
            style={{
              paddingInline: 10,
              display: "flex",
              width: "100%",
              marginTop: -20,
            }}
          >
            <Col span={24} style={{ paddingRight: 5 }}>
              <span style={{ paddingBottom: 10, color: "#000000" }}>
                Escolha uma Comum
              </span>
              <Select
                options={comuns}
                onChange={(e) => getPessoas(e)}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </Row>
        <Row>
          <Table
            footer={() => <>Total de Irmãos: {membros?.length}</>}
            dataSource={membros}
            columns={columns}
            pagination={false}
            scroll={{ y: "33vh" }}
            style={{ width: "100%" }}
            size="small"
          />
        </Row>
      </Modal>
    </Spin>
  );
};
