import axios from  'axios';
import { getConfigLaravel as getConfig } from '../configs/sistemaConfig';
import { response } from 'express';

const api = axios.create({
    baseURL: window.location.hostname == "localhost" ? process.env.REACT_APP_API_DESV : process.env.REACT_APP_API_PROD,
    withCredentials: true,
    xsrfCookieName: "csrf-token",
    xsrfHeaderName: 'X-CSRF-TOKEN',
});

export const useApiAuthentication = () => ({
    //Validate
    validateToken: async (token: string) => {
        const response = await api.post('/auth/validate', { token }, getConfig("priv"));
        return response.data;
    },
    signin: async (cpf: string, password: string) => {
        const response = await api.post('/auth/login', { cpf, password });
        return response.data;
    },
    logout: async (token: string) => {
        const response = await api.post('/auth/logout', { token }, getConfig("pub"));
        return response.data;
    },
    newPass: async (cpf: string) => {
        const response = await api.post('/auth/newPass', { cpf }, getConfig("pub"));
        return response.data;
    },
    resetPassword: async (password: string, old_password: string) => {
        const response = await api.post('/auth/resetPassword', { password, old_password }, getConfig("priv"));
        return response.data;
    }
});