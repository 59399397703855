import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  FloatButton,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { MaskedInput } from "antd-mask-input";
import { ColumnsType } from "antd/es/table";
import { CardFI } from "../../components/CardFI";

export const ComumPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [comuns, setComuns] = useState([]);
  const api = useApi();
  const [form] = Form.useForm();
  const [codigo, setCodigo] = useState("00-0000");

  interface DataType {
    id: string;
    ordem: number;
    codigo: string;
    nome: string;
    setor_id: number;
  }

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "Código",
    //   dataIndex: "codigo",
    //   key: "codigo",
    //   width: 150,
    //   sorter: (a, b) => a.codigo.localeCompare(b.codigo),
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <>
          <Row>
            <a
              onClick={() => {
                editComum(record);
              }}
            >
              {record.codigo} - {record.nome}
            </a>
          </Row>
          <Row>Setor: {record.setor_id}</Row>
        </>
      ),
    },
  ];

  const onHandleOk = () => {
    setOpenModal(true);
  };

  const onHandleCancel = () => {
    setOpenModal(false);
  };

  const getComuns = async () => {
    await api.getComuns().then((res) => {
      if (res) {
        res.forEach((item: any) => {});
        setComuns(res);
      } else {
        message.error("Não há comuns cadastrados");
        setComuns([]);
      }
    });
  };

  const postComum = async (values: any) => {
    await api.postComum(values).then((res) => {
      message.success(res.message);
      form.resetFields();
      setOpenModal(false);
      getComuns();
    });
  };

  const editComum = (comum: any) => {
    form.resetFields();
    setCodigo("");
    setOpenModal(true);
    form.setFieldsValue(comum);
    setCodigo(comum.codigo);
  };

  useEffect(() => {
    getComuns();
  }, []);

  return (
    <CardFI id="comum" title="Cadastro de Comum" size="80vw" display="block">
      <>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: 5,
          }}
        >
          <Table
            footer={() => <>Total de Igrejas: {comuns.length}</>}
            dataSource={comuns}
            columns={columns}
            pagination={false}
            scroll={{ y: "35vh" }}
            style={{ width: "99%" }}
            size="small"
          />
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              form.resetFields();
              onHandleOk();
            }}
            icon={<PlusOutlined />}
            type={"default"}
          >
            Cadastrar Igreja
          </Button>
        </Row>

        <Modal
          title={"Cadastro de Igreja"}
          open={openModal}
          onOk={onHandleOk}
          onCancel={onHandleCancel}
          footer
        >
          <Form form={form} onFinish={postComum}>
            <Form.Item name={"id"} hidden>
              <Input />
            </Form.Item>
            <Form.Item name={"setor_id"} label={"Setor"}>
              <Select
                options={[
                  { value: 1, label: "Setor 1" },
                  { value: 2, label: "Setor 2" },
                  { value: 3, label: "Setor 3" },
                  { value: 4, label: "Setor 4" },
                  { value: 5, label: "Setor 5" },
                  { value: 6, label: "Setor 6" },
                  { value: 7, label: "Setor 7" },
                ]}
              />
            </Form.Item>
            <Form.Item name={"codigo"} label={"Código"}>
              {/* <Input />*/}
              <MaskedInput
                mask={"00-0000"}
                placeholder="00-0000"
                value={codigo}
              />
            </Form.Item>
            <Form.Item name={"nome"} label={"Nome"}>
              <Input
                onInput={(e) =>
                  ((e.target as HTMLInputElement).value = (
                    e.target as HTMLInputElement
                  ).value.toUpperCase())
                }
              />
            </Form.Item>
            {/* <Form.Item name={'cargo'} label={'Cargo'}>
                        <Input />
                    </Form.Item> */}
            <Form.Item>
              <Button htmlType="submit">Gravar</Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    </CardFI>
  );
};
