import axios from  'axios';
import { getConfigLaravel as getConfig } from '../configs/sistemaConfig';

const api = axios.create({
    baseURL: window.location.hostname == "localhost" ? process.env.REACT_APP_API_DESV : process.env.REACT_APP_API_PROD,
    withCredentials: true,
    xsrfCookieName: "csrf-token",
    xsrfHeaderName: 'X-CSRF-TOKEN',
});

export const useApiEstado = () => ({

    //Estado
    getStateCombo: async () => {
        const response = await api.post('/state/combo', {}, getConfig('pub'));
        return response.data;
    },
    getEstadoNome: async (uf: string) => {
        const response = await api.post('/estado/get/nome', { uf }, getConfig('priv'));
        return response.data;
    },
    
});