import axios from  'axios';
import { getConfigLaravel as getConfig } from '../configs/sistemaConfig';

const api = axios.create({
    baseURL: window.location.hostname == "localhost" ? process.env.REACT_APP_API_DESV : process.env.REACT_APP_API_PROD,
    withCredentials: true,
    xsrfCookieName: "csrf-token",
    xsrfHeaderName: 'X-CSRF-TOKEN',
});

export const useApiCidade = () => ({
    
    //Cidade
    getCityCombo: async (uf: string) => {
        const response = await api.post('/city/combo', { uf }, getConfig('pub'));
        return response.data;
    },
    getCidadeNome: async (nome: string) => {
        const response = await api.post('/cidade/get/nome', { nome }, getConfig('priv'));
        return response.data;
    },

});