import { CloseCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import background from "../../assets/back.jpg";

export const CardFI = ({
  id,
  display,
  children,
  title,
  size,
}: {
  id: string;
  display: string;
  children: JSX.Element;
  title: string;
  size: string;
}) => {
  const navigate = useNavigate();
  const closeCard = () => {
    navigate("/sistema");
  };
  return (
    // <Draggable>
    <Card
      id={id}
      title={title}
      //className={styles.CardFIStyle}
      // extra={<a href='../sistema'><CloseCircleOutlined style={{ fontSize:32, color: '#fff' }} /></a>}
      style={{
        display: display,
        width: size,
        height: "auto",
        border: "none",
        borderRadius: "8px 8px 0px 0px",
        //boxShadow: "5px 5px 12px #2E2E2E",
        backgroundColor: "#333333",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "repeat",
        backgroundPosition: "top",
        color: "#ffffff",
      }}
      extra={
        <span style={{ marginRight: -25, paddingInline: 5 }}>
          <Button
            //shape="circle"
            //type="text"
            icon={<CloseCircleFilled style={{ color: "red" }} />}
            //danger
            onClick={() => closeCard()}
            size="small"
            style={{ backgroundColor: "#282c34", border: "none" }}
          />
        </span>
      }
    >
      <div className={styles.CardMark}>{children}</div>
    </Card>
    // </Draggable>
  );
};
