import { createContext } from "react";
import { SetorType } from "../../types/SetorType";
import { ComumType } from "../../types/ComumType";
import { UserType } from "../../types/UserType";

export type AuthContextType = {
  user: UserType | null;
  setor: SetorType | null;
  comuns: Array<ComumType> | null;
  comum_sel: ComumType | null;
  signin: (cpf: string, password: string) => Promise<boolean>;
  signout: () => void;
  setComum: (comum: number | null) => Promise<boolean>;
  perfil: any | null;
};

export const AuthContext = createContext<AuthContextType>(null!);
