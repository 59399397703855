export const sistemaNameSSO = 'CheckIn';
export const sistemaDescricao = 'Sistema de CheckIn das Reuniões e Eventos';
export const sistemaVersao = '1.0.0-00';
export const domainNameProd = 'checkinst3.rdca.com.br';
export const domainNameHomo = 'checkinst3-homo.rdca.com.br';
export const domainNameDesv = 'localhost';

export const perfisSistema = {
    ADM_SYS: 'ADM_SYS',
    USUARIO_ADMST3: 'USUARIO_ADMST',
    USUARIO_COMUM: 'USUARIO_COMUM',
    USUARIO_IRMAO: 'USUARIO_IRMAO',
}

export const getConfig = (type: string) => {
  const configPub = {
    headers: {
      'Access-Control-Allow-Origin': `${window.location.origin}`,
      'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTION',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  const configPriv = {
    headers: {
      'Access-Control-Allow-Origin': `${window.location.origin}`,
      'Access-Control-Allow-Headers': 'Authorization',
      'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTION',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  };

  if(type === "priv"){
    return configPriv;
  } 
  return configPub;
};

export const getConfigLaravel = (type: string) => {
  const configPub = {
      headers: {
          "Access-Control-Allow-Origin": '*',
          "Access-Control-Allow-Headers": "Authorization",
          "Access-Control-Allow-Methods": "*",
          "Content-Type": "application/json;charset=UTF-8",
      }
  }
  const configPriv = {
      headers: {
          'Access-Control-Allow-Origin': window.location.origin,
          'Access-Control-Allow-Headers': 'Authorization',
          'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTION',
          'Content-Type': 'application/json;charset=UTF-8',
          "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
          "Tenant": `${localStorage.getItem('localId')}`,
          "Token": `${localStorage.getItem('authToken')}`,
      }
  }
  const configPubFile = {
      headers: {
          "Access-Control-Allow-Origin": '*',
          "Access-Control-Allow-Headers": "Authorization",
          "Access-Control-Allow-Methods": "*",
          "Content-Type": 'multipart/form-data',
      }
  }
  const configPrivFile = {
      headers: {
          'Access-Control-Allow-Origin': window.location.origin,
          'Access-Control-Allow-Headers': 'Authorization',
          'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTION',
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
          "Tenant": `${localStorage.getItem('localId')}`,
          "Token": `${localStorage.getItem('authToken')}`,
      }
  }
  if(type === "priv"){
    return configPriv;
  } else if(type === "pub"){
    return configPub;
  }else if(type === "privF"){
    return configPrivFile;
  } else if(type === "pubF"){
    return configPubFile;
  }
  //return configPub;
}