import { ApiFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd"
import { useEffect, useState } from "react"
import { useApi } from "../../hooks/useApi";

export const Dashboard = () => {
    const api = useApi();
    const [evento, setEvento] = useState([]);
    const [checkins, setCheckins] = useState([]);

    const getEventDay = async () => {
        await api.getEventoDay().then((res) => {
            setEvento(res);
        })
    }
    
    useEffect(() => {
        getEventDay();
    }, []);

    return (
        <>
            {/* <Row style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                <span style={{ fontSize: 28, color: '#fff' }}>Evento do Dia</span>
                <Col span={24}>
                    {evento ? (
                        <Card title={evento?.nome}>

                        </Card>
                    ) : null}
                    
                </Col>
            </Row> */}
        </>
    )
}