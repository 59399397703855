import axios from  'axios';

const getConfig = (type: string) => {
    const configPub = {
        headers: {                  
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Authorization", 
            "Access-Control-Allow-Methods": "*" ,
            "Content-Type": "application/json;charset=UTF-8" ,                 
        },
    };
    
    const configPriv = {    
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Authorization", 
            "Access-Control-Allow-Methods": "*" ,
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
            "Token": `${localStorage.getItem('authToken')}`,
        }
    };

    if(type === "priv"){
        return configPriv;
    } 
           
    return configPub;
}

const api = axios.create({
    baseURL: window.location.hostname == "localhost" ? process.env.REACT_APP_API_DESV : process.env.REACT_APP_API_PROD,
    withCredentials: true,
    xsrfCookieName: "csrf-token",
    xsrfHeaderName: 'X-CSRF-TOKEN',
});

export const useApi = () => ({
    //Setores
    getSetores: async () => {
        const response = await api.post('/getSetores', {}, getConfig('priv'));
        return response.data;
    },
    postSetor: async (values: any) => {
        const response = await api.post('/postSetores', {values}, getConfig('priv'));
        return response.data;
    },
    setSetor: async (setor_id: number) => {
        const response = await api.post('/setSetores', {setor_id}, getConfig('priv'));
        return response.data;
    },
    //Comuns
    getComuns: async () => {
        const response = await api.post('/getComuns', {}, getConfig('priv'));
        return response.data;
    },
    postComum: async (values: any) => {
        const response = await api.post('/postComum', {values}, getConfig('priv'));
        return response.data;
    },
    setComum: async (comum_id: number) => {
        const response = await api.post('/setComum', {comum_id}, getConfig('priv'));
        return response.data;
    },
    //Cargos
    getCargos: async () => {
        const response = await api.post('/getCargos', {}, getConfig('priv'));
        return response.data;
    },
    postCargo: async (values: any) => {
        const response = await api.post('/postCargo', {values}, getConfig('priv'));
        return response.data;
    },
    //Pessoas
    getPessoa: async(pessoa: string) => {
        const response  = await api.post('/getPessoa', {pessoa}, getConfig('priv'));
        return response.data;
    },
    getPessoas: async (comum: number) => {
        const response = await api.post('/getPessoas', {comum}, getConfig('priv'));
        return response.data;
    },
    listPessoas: async(search: string) => {
        const response = await api.post('/listPessoas', {search}, getConfig('priv'));
        return response.data;
    },
    postPessoa: async (values: any) => {
        const response = await api.post('/postPessoa', {values}, getConfig('priv'));
        return response.data;
    },
    getCrachasPDF: async (comum: number | null) => {
        const response = await api.post('/getAllCracha', { comum }, getConfig('priv'));
        return response.data;
    },
    deletePessoa: async (id: number) => {
        const response = await api.post('/deletePessoa', { id }, getConfig('priv'));
        return response.data;
    },
    //Evento
    getEventos: async (search: string | null) => {
        const response = await api.post('/getEventos', {search}, getConfig('priv'));
        return response.data;
    },
    getEvento: async (id: string) => {
        const response = await api.post('getEvento', {id}, getConfig('priv'));
        return response.data;
    },
    postEvento: async (values: any) => {
        const response = await api.post('/postEvento', {values}, getConfig('priv'));
        return response.data;
    },
    getCheckIns: async (id: string) => {
        const response = await api.post('/getCheckIns', {id}, getConfig('priv'));
        return response.data;
    },
    getEventoDay: async () => {
        const response = await api.post('/getEventoDay', {}, getConfig('priv'));
        return response.data;
    },
    //CheckIn
    checkIn: async (pessoa: string, evento: string, data: Date) => {
        const response = await api.post('/checkIn', {pessoa, evento, data}, getConfig('priv'));
        return response.data;
    },
    //Menu
    getMenuDashboard: async (font: string) => {
        const response = await api.post(font, {}, getConfig("priv"));
        return response.data;
    },
    getMenuParentCombo:async () => {
        const response = await api.post("/menu/parent/combo", {}, getConfig("priv"));
        return response.data;        
    },
    listMenu: async(name: string, parent: string, route: string) => {
        const response = await api.post("/menu/list", {'name': name, 'parent': parent, 'route': route}, getConfig("priv"));
        return response.data;
    },
    saveMenu:async (values:any) => {
        const response = await api.post('/menu/save', {values}, getConfig("priv"));
        return response.data;
    },
    getOptionsSelectPerfilMenu:async () => {
        const response = await api.post('/menu/profile', {}, getConfig('priv'));
        return response.data;
    },
    getMenu:async (id:string) => {
        const response = await api.post('/menu/find', {id}, getConfig('priv'));
        return response.data;
    },
    //Perfil
    getComboPerfil: async() => {
        const response = await api.post('/combo/perfil', {}, getConfig('priv'));
        return response.data;
    },
});