import {
  ApiFilled,
  EditOutlined,
  EyeOutlined,
  OrderedListOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  FloatButton,
  Form,
  Input,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Tag,
  TimePicker,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { ColumnsType } from "antd/es/table";
import Meta from "antd/es/card/Meta";
import { CardFI } from "../../components/CardFI";

export const EventoPage = () => {
  const api = useApi();
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [ativo, setAtivo] = useState(true);
  const [eventos, setEventos] = useState([]);
  const timeFormat = "HH:mm";
  const dateFormat = "DD/MM/YYYY";
  const [data, setData] = useState("");
  const [hora, setHora] = useState("");
  const [openCheck, setOpenCheck] = useState(false);
  const [listCheck, setListCheck] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [filters, setFilters] = useState([]);

  interface DataType {
    id: number;
    nome: string;
    descricao: string;
    data: string;
    hora: string;
    ativo: boolean;
  }

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "Codigo",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: (a, b) => a.id - b.id,
    //   width: "10%",
    // },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      filters: filters,
      filterMode: "tree",
      filterSearch: true,
      render: (_, record) => {
        return (
          <>
            <Row>
              <a
                onClick={(e) => {
                  editEvento(record);
                }}
              >
                <strong>{record.nome}</strong>
              </a>
            </Row>
            <Row style={{ fontSize: 10 }}>{record.descricao}</Row>
            <Row style={{ fontSize: 10 }}>
              {record.data} às {record.hora}
            </Row>
            {record.ativo ? (
              <Tag style={{ fontSize: 10 }} color="success">
                Ativo
              </Tag>
            ) : (
              <Tag style={{ fontSize: 10 }} color="error">
                Cancelado
              </Tag>
            )}
          </>
        );
      },
      //onFilter: (value: string, record) => record.nome.includes(value),
    },
    /*{
      title: "Descrição",
      dataIndex: "descricao",
      key: "descricao",
    },*/
    // {
    //   title: "Data",
    //   dataIndex: "data",
    //   key: "data",
    //   sorter: (a, b) => a.data.localeCompare(b.data),
    //   sortDirections: ["ascend", "descend"],
    //   width: "15%",
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Row>{record.data}</Row>
    //         <Row>{record.hora}</Row>
    //       </>
    //     );
    //   },
    // },
    /*{
      title: "Hora",
      dataIndex: "hora",
      key: "hora",
      width: "15%",
    },*/
    // {
    //   title: "Ativo",
    //   dataIndex: "ativo",
    //   key: "ativo",
    //   render: (_, record) =>
    //     record.ativo ? <Checkbox checked /> : <Checkbox checked={false} />,
    //   width: "10%",
    // },
    // {
    //   title: "Ação",
    //   dataIndex: "",
    //   key: "acao",
    //   width: "10%",
    //   render: (_, record) => (
    //     <Space>
    //       <Button
    //         type="link"
    //         size="small"
    //         icon={<EditOutlined />}
    //         onClick={(e) => {
    //           editEvento(record);
    //         }}
    //       />
    //       <Button
    //         type="link"
    //         size="small"
    //         icon={<OrderedListOutlined />}
    //         onClick={(e) => getCheckIns(record.id.toString())}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  const onHandleOk = () => {
    setOpenModal(true);
  };

  const onHandleCancel = () => {
    setOpenModal(false);
  };

  const onChangeAtivo = (checked: boolean) => {
    setAtivo(checked);
  };

  const onFinish = async (values: any) => {
    //values.hora = values.hora.$d.toString().split(' ')[4];
    await api.postEvento(values).then((res) => {
      if (res.evento) {
        message.success(res.mensagem);
        form.resetFields();
        setOpenModal(false);
        getEventos();
      } else {
        message.error(res.mensagem);
      }
    });
  };

  const getEventos = async () => {
    await api.getEventos(null).then((res) => {
      if (res) {
        const eventosList: any = [];
        const newFilters: any = [];
        res.map((item: any) => {
          eventosList.push(item);
          newFilters.push({ text: item.nome, value: item.nome });
        });
        setFilters(newFilters);
        setEventos(eventosList);
      } else {
        message.error("Não há comuns cadastrados");
        setEventos([]);
      }
    });
  };

  const editEvento = async (evento: any) => {
    form.resetFields();
    await api.getEvento(evento.id).then((res) => {
      form.setFieldsValue(res);
      setOpenModal(true);
    });
    //form.setFieldsValue(evento);
    //setData(evento.data);
    //setHora(evento.hora);
  };

  const getCheckIns = async (id: string) => {
    await api.getCheckIns(id).then((res) => {
      const checkinsList: any = [];
      const newFilters: any = [];
      res.map((item: any) => {
        checkinsList.push(item);
        newFilters.push({ text: item.nome, value: item.nome });
      });
      setFilters(newFilters);
      setListCheck(checkinsList);
      setOpenCheck(true);
    });
  };

  useEffect(() => {
    getEventos();
  }, []);

  return (
    <CardFI id="eventos" title={"Eventos"} size="80vw" display="block">
      <>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: 10,
          }}
        >
          <Table
            footer={() => <>Total de Eventos: {eventos.length}</>}
            dataSource={eventos}
            columns={columns}
            pagination={false}
            scroll={{ y: "35vh" }}
            style={{ width: "100%" }}
            size="small"
          />
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              form.resetFields();
              onHandleOk();
            }}
            icon={<PlusOutlined />}
            type={"default"}
          >
            Cadastrar Evento
          </Button>
        </Row>

        <Modal
          title={"Cadastro de Evento"}
          open={openModal}
          onOk={onHandleOk}
          onCancel={onHandleCancel}
          footer
        >
          <Form form={form} layout={"vertical"} onFinish={onFinish}>
            <Row style={{ width: "100%" }}>
              <Col span={6}>
                <Form.Item label={"Código"} name={"id"}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={18} style={{ paddingLeft: 10 }}>
                <Form.Item label={"Nome"} name={"nome"}>
                  <Input
                    onInput={(e) =>
                      ((e.target as HTMLInputElement).value = (
                        e.target as HTMLInputElement
                      ).value.toUpperCase())
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "100%", marginTop: -15 }}>
              <Col span={24}>
                <Form.Item label={"Descrição"} name={"descricao"}>
                  <TextArea
                    onInput={(e) =>
                      ((e.target as HTMLInputElement).value = (
                        e.target as HTMLInputElement
                      ).value.toUpperCase())
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "100%", marginTop: -15 }}>
              <Col>
                <Form.Item label={"Data"} name={"data"}>
                  <MaskedInput mask={"00/00/0000"} />
                </Form.Item>
              </Col>
              <Col style={{ paddingLeft: 10 }}>
                <Form.Item label={"Hora"} name={"hora"}>
                  <MaskedInput mask={"00:00"} />
                </Form.Item>
              </Col>
              <Col style={{ paddingLeft: 10 }}>
                <Form.Item label={"Status"} name={"ativo"}>
                  {/* <Switch 
                                    defaultChecked 
                                    onChange={onChangeAtivo} 
                                    checkedChildren={'Ativo'}
                                    unCheckedChildren={'Inativo'}
                                /> */}
                  <Checkbox checked>Ativo</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button type="primary" htmlType="submit">
                Gravar
              </Button>
            </Row>
          </Form>
        </Modal>
        <Modal
          open={openCheck}
          title={"Lista de presentes"}
          onCancel={() => {
            setOpenCheck(false);
            setListCheck([]);
          }}
          footer
        >
          <div>
            <List
              size="small"
              // header={<div style={{ display: 'flex', justifyContent: 'center', padding: 10, fontSize: 28, color: '#fcfcfc', backgroundColor: '#0000009e', borderRadius: 10 }}>Lista de Pedidos</div>}
              //bordered
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
              dataSource={listCheck!}
              renderItem={(item) => (
                <List.Item style={{ display: "flex", width: "100%" }}>
                  <Row
                    style={{
                      width: "100%",
                      backgroundColor: "#ffffff",
                      borderRadius: 5,
                      padding: 2.5,
                    }}
                  >
                    <Col
                      span={4}
                      style={{
                        paddingInline: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Row style={{ fontSize: 10, color: "#2c2c2c" }}>
                        Código
                      </Row>
                      <Row style={{ fontSize: 12, color: "#0f0f0f" }}>
                        <strong>{item["id"]}</strong>
                      </Row>
                    </Col>
                    <Col
                      span={13}
                      style={{
                        paddingInline: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Row style={{ fontSize: 10, color: "#2c2c2c" }}>
                        Irmã(o)
                      </Row>
                      <Row style={{ fontSize: 12, color: "#0f0f0f" }}>
                        <strong>{item["nome"]}</strong>
                      </Row>
                      <Row>
                        {item["pessoa_nome"]} / {item["pessoa_cargo"]} -{" "}
                        {item["pessoa_comum"]}
                      </Row>
                    </Col>
                    <Col
                      span={7}
                      style={{
                        paddingInline: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <Row style={{ fontSize: 10, color: "#2c2c2c" }}>
                        Data/Hora
                      </Row>
                      <Row
                        style={{
                          fontSize: 12,
                          color: "#0f0f0f",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <strong>{item["checkin"]}</strong>
                      </Row>
                    </Col>
                    {/* <Col span={4} style={{ paddingInline: 10, display: 'flex', justifyContent: 'space-between', flexDirection: 'column',}}>
                                        <Row style={{ fontSize: 10, color: '#2c2c2c' }}>
                                            &nbsp;
                                        </Row>
                                        <Row style={{ fontSize: 12, color: '#0f0f0f' }}>
                                            <Space>
                                                <Button onClick={() => {editEvento(item);}}><EditOutlined /></Button>
                                                <Button onClick={() => {editEvento(item);}}><EyeOutlined /></Button>
                                            </Space>
                                            
                                        </Row>
                                    </Col>                                   */}
                  </Row>
                </List.Item>
              )}
            />
          </div>
        </Modal>
      </>
    </CardFI>
  );
};
