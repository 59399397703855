import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  FloatButton,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { ColumnsType } from "antd/es/table";
import { CardFI } from "../../components/CardFI";

export const CargoPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [cargos, setCargos] = useState([]);
  const api = useApi();
  const [form] = Form.useForm();

  interface DataType {
    id: number;
    nome: string;
  }

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "Código",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 150,
    //   sorter: (a, b) => a.id - b.id,
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <a
          onClick={() => {
            editCargo(record);
          }}
        >
          {record.nome}
        </a>
      ),
    },
    // {
    //   title: "Ações",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (_, record) => (
    //     <>
    //       <Button
    //         type="link"
    //         size="small"
    //         onClick={() => {
    //           editCargo(record);
    //         }}
    //         icon={<EditOutlined />}
    //       />
    //     </>
    //   ),
    //   width: 100,
    // },
  ];

  const onHandleOk = () => {
    setOpenModal(true);
  };

  const onHandleCancel = () => {
    setOpenModal(false);
  };

  const getCargos = async () => {
    await api.getCargos().then((res) => {
      if (res) {
        res.forEach((item: any) => {});
        setCargos(res);
      } else {
        message.error("Não há cargos cadastrados");
        setCargos([]);
      }
    });
  };

  const postCargo = async (values: any) => {
    await api.postCargo(values).then((res) => {
      message.success(res.message);
      form.resetFields();
      setOpenModal(false);
      getCargos();
    });
  };

  const editCargo = (cargo: any) => {
    form.setFieldsValue(cargo);
    setOpenModal(true);
  };

  useEffect(() => {
    getCargos();
  }, []);

  return (
    <CardFI id="cargo" title="Cargos e Funções" size="90vw" display="block">
      <>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            width: "99%",
            padding: 2.5,
          }}
        >
          <Table
            footer={() => <>Total de Cargos / Funções: {cargos.length}</>}
            dataSource={cargos}
            columns={columns}
            pagination={false}
            scroll={{ y: "30vh" }}
            style={{ width: "100%" }}
            size="small"
          />
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              form.resetFields();
              onHandleOk();
            }}
            icon={<PlusOutlined />}
          >
            Novo Cargo / Função
          </Button>
        </Row>
        <Modal
          title={"Cadastro de Cargos / Funções"}
          open={openModal}
          onOk={onHandleOk}
          onCancel={onHandleCancel}
          footer
        >
          <Form form={form} onFinish={postCargo}>
            <Form.Item name={"id"} hidden>
              <Input />
            </Form.Item>
            <Form.Item name={"nome"} label={"Nome"}>
              <Input
                onInput={(e) =>
                  ((e.target as HTMLInputElement).value = (
                    e.target as HTMLInputElement
                  ).value.toUpperCase())
                }
              />
            </Form.Item>
            {/* <Form.Item name={'codigo'} label={'Código'}>
                        <Input />
                    </Form.Item> */}
            {/* <Form.Item name={'cargo'} label={'Cargo'}>
                        <Input />
                    </Form.Item> */}
            <Form.Item>
              <Button htmlType="submit">Gravar</Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    </CardFI>
  );
};
