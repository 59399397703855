import styles from "./style.module.css";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Layout,
  List,
  Modal,
  Popover,
  QRCode,
  Row,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import {
  ApiFilled,
  ArrowRightOutlined,
  BankOutlined,
  CoffeeOutlined,
  ContactsOutlined,
  DesktopOutlined,
  DotChartOutlined,
  FolderOpenFilled,
  HomeOutlined,
  IdcardFilled,
  MenuOutlined,
  PoweroffOutlined,
  QrcodeOutlined,
  QuestionCircleFilled,
  RocketOutlined,
  ScanOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  StarFilled,
  ToolOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { useContext, useEffect, useState } from "react";
import { Dashboard } from "../Dashboard";
import { CheckIn } from "../CheckIn";
import { EventoPage } from "../Evento";
import { PessoaPage } from "../Pessoa";
import Item from "antd/es/list/Item";
import { ComumPage } from "../Comum";
import { CargoPage } from "../Cargo";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";
import { useApiAuthentication } from "../../hooks/useApiAuthentication";

interface CrachaType {
  id: number | undefined;
  nome: string | undefined;
  tipo: string | undefined;
  comum: string | undefined;
  comum_id: string | undefined;
  cargos: [{ id: number; nome: string }] | undefined;
  validade: string | undefined;
  ativo: boolean | undefined;
}

export const HomePage = () => {
  const auth = useContext(AuthContext);
  const api = useApiAuthentication();
  const apiUse = useApi();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [openNovaSenha, setOpenNovaSenha] = useState(false);
  const [comuns, setComuns] = useState([
    {
      nome: auth?.comum_sel?.nome,
      codigo: auth.comum_sel?.nome,
    },
  ]);
  const [openModalComum, setOpenModalComum] = useState(false);
  const [comum, setComum] = useState<{
    nome: string | null;
    codigo: number | null;
  } | null>(null);
  const [openLoad, setOpenLoad] = useState(false);
  const [msgLoad, setMsgLoad] = useState("");
  const [cracha, setCracha] = useState<CrachaType>();
  const [openModalQrCode, setOpenModalQrCode] = useState(false);
  const [size, setSize] = useState(170);

  const showDrawer = () => {
    setOpen(true);
  };

  const openModalSelectComum = () => {
    setOpenModalComum(true);
  };

  const onCloseSelectComum = () => {
    setOpenModalComum(false);
  };

  const setComumClick = async (comum: {
    nome: string | null;
    codigo: number | null;
  }) => {
    /*localStorage.setItem("localId", comum.codigo.toString());
    localStorage.setItem("localNome", comum.nome);
    setComum(comum);
    setOpenModalComum(false);
    window.location.reload();*/
    setMsgLoad("Atualizando para a nova comum. Aguarde...");
    setOpenLoad(true);
    //if (comum.codigo !== null) {
    const setComum = await auth.setComum(comum.codigo).then((newComum) => {
      if (newComum) {
        const comumCodigo =
          comum.codigo !== null ? comum.codigo.toString() : "";
        localStorage.setItem("localId", comumCodigo);
        localStorage.setItem("localNome", comum!.nome!);
        navigate("/sistema");
      } else {
        auth.signout();
        localStorage.setItem("localId", "");
        localStorage.setItem("localNome", "");
      }
      setOpenModalComum(false);
    });
    /*} else {
      localStorage.setItem("localId", "");
      localStorage.setItem("localNome", "");
      setComum({ codigo: null, nome: "Admin" });
      setOpenModalComum(false);
    }*/
    setMsgLoad("");
    setOpenLoad(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getComuns = () => {
    const comumx = auth?.comum_sel;
    const comunsx = auth?.comuns;
    const comumN: any = [];
    comumN.push({ nome: "Admin", codigo: null });
    comunsx?.map((item: any) => {
      if (comumx?.id !== item.id) {
        comumN.push({ nome: item.nome, codigo: item.id });
      }
    });
    setComuns(comumN);

    if (
      localStorage.getItem("localId") &&
      localStorage.getItem("localId") !== "0" &&
      localStorage.getItem("localId") !== ""
    ) {
      setComum({
        nome: localStorage.getItem("localNome")!,
        codigo: parseInt(localStorage.getItem("localId")!),
      });
    } else {
      if (comumx) {
        localStorage.setItem("localId", comumx!.id!.toString());
        localStorage.setItem("localNome", comumx!.nome!);
        setComum({ codigo: comumx!.id!, nome: comumx!.nome! });
      } else {
        localStorage.setItem("localId", "0");
        localStorage.setItem("localNome", "Clique aqui e selecione.");
        setComum({
          codigo: 0,
          nome: "Clique aqui e selecione.",
        });
      }
    }
  };

  const setNovaSenha = async () => {
    setMsgLoad("Definindo nova senha. Aguarde...");
    setOpenLoad(true);
    await api
      .resetPassword(
        form.getFieldValue("nova_senha"),
        form.getFieldValue("senha_atual")
      )
      .then((res) => {
        message.warning({ content: res.message });
      });
    form.resetFields();
    setMsgLoad("");
    setOpenLoad(false);
    setOpenNovaSenha(false);
  };

  // const createQrCode = async (id: string) => {
  //   setMsgLoad("Criando o QRCode. Aguarde...");
  //   setOpenLoad(true);
  //   await api.getPessoa(id).then((pessoa) => {
  //     pessoa.key = pessoa.id;
  //     setCracha(pessoa);
  //     setOpenModalQrCode(true);
  //   });
  //   setOnLoad(false);
  //   setMsgLoad("");
  // };

  const carregarDadosQRCode = async () => {
    const pessoa = auth.user?.pessoa;
    const cracha_reg = {
      id: pessoa?.id,
      nome: pessoa?.nome,
      tipo: pessoa?.tipo,
      comum: pessoa?.comum_nome,
      comum_id: pessoa?.comum_id.toString(),
      cargos: pessoa?.cargos,
      validade: pessoa?.validade,
      ativo: pessoa?.ativo,
    };
    setCracha(cracha_reg);
    setOpenModalQrCode(true);
  };

  const onOkModalQrCode = () => {
    setOpenModalQrCode(true);
  };

  const onCancelModalQrCode = () => {
    setOpenModalQrCode(false);
  };

  useEffect(() => {
    navigate("dashboard");
    getComuns();
    /*if (localStorage.getItem("localId") != null) {
      setComumClick({
        nome: localStorage.getItem("localNome")!,
        codigo: parseInt(localStorage.getItem("localId")!),
      });
    }*/
  }, []);

  return (
    <>
      <Spin spinning={openLoad} tip={msgLoad}>
        <div className={styles.container}>
          <Layout
            style={{ minHeight: "100vh", backgroundColor: "transparent" }}
          >
            {/*auth.user?.isadm ? (
                        <Sider collapsed={collapsed} onCollapse={value => setCollapsed(value)} style={{ padding: '5px 10px 10px 10px', backgroundColor: 'transparent', minWidth: 250, width: 250 }}>
                            <Row style={{ height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff8b', borderRadius: 10 }}>
                                Administrador
                            </Row>
                            <Row>
                                <MenuCustom collapsed={true} font={"system/menu"} onChange={handleMenu} theme="light"/>
                            </Row>
                        </Sider>
                    ) : (
                        <></>
                    )*/}
            <Layout style={{ background: "transparent" }}>
              <Header style={{ padding: 0, backgroundColor: "transparent" }}>
                <Row style={{ width: "99vw" }}>
                  <Col span={2} style={{ paddingLeft: 10 }}>
                    {/* <a href='../sistema'><LogoTopoApp /></a> */}
                    <Space>
                      {/* <Badge count={3}> */}
                      <Avatar
                        onClick={showDrawer}
                        style={{
                          cursor: "pointer",
                          fontWeight: 500,
                          color: "#050505",
                          backgroundColor: "#d4a300",
                        }}
                      >
                        <MenuOutlined />
                      </Avatar>
                      {/* </Badge> */}
                    </Space>
                  </Col>
                  <Col span={22}>
                    <Row
                      style={{
                        color: "#ffffff",
                        paddingTop: 5,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/* <span style={{ fontSize: 18, fontWeight: 500, color: '#000' }}>{auth.user?.name}</span> */}
                      {/* <MenuCustom collapsed={collapsed} font={"system/menu"} onChange={handleMenu} theme="light"/> */}
                      <Col>
                        <Row>
                          <div style={{ marginTop: -12 }}>
                            {/* <LogoTopoApp /> */}
                          </div>

                          <span
                            style={{
                              fontWeight: 600,
                              fontFamily: "Roboto",
                              fontSize: 28,
                              textShadow: "2px 2px 4px #313131",
                            }}
                          >
                            CheckInST3
                          </span>
                          <span
                            style={{
                              fontFamily: "Roboto",
                              fontSize: 38,
                              fontWeight: 50,
                              marginTop: -5,
                              paddingInline: 5,
                              textShadow: "2px 2px 4px #313131",
                            }}
                          >
                            |
                          </span>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          fontWeight: 100,
                          fontFamily: "Roboto",
                          fontSize: 12,
                          height: 38,
                          marginTop: -12,
                        }}
                      >
                        <Row
                          style={{
                            width: "100%",
                            paddingInline: 5,
                            height: 20,
                            textShadow: "2px 2px 4px #313131",
                          }}
                        >
                          Sistema de
                        </Row>
                        <Row
                          style={{
                            width: "100%",
                            paddingInline: 5,
                            height: 20,
                            textShadow: "2px 2px 4px #313131",
                          }}
                        >
                          Registro de CheckIn
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col style={{ paddingRight: 10, paddingTop: -5, display: 'flex', justifyContent: 'right' }}>
                                    <Row> */}
                  {/* <Space>                                        
                                            <Badge count={3}>
                                                <Avatar onClick={showDrawer} style={{  cursor: 'pointer', fontWeight: 500,  color: '#050505', backgroundColor: '#d4a300'}}><MenuOutlined /></Avatar>
                                            </Badge>
                                        </Space> */}
                  {/* </Row>
                                </Col> */}
                </Row>
              </Header>
              <Content
                style={{
                  margin: "0px 5px 5px 5px",
                  backgroundColor: "transparent",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Col
                  style={{
                    width: "100%",
                    height: "77vh",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems:
                      "center" /*, backgroundColor: '#ffffff28', borderRadius: 8*/,
                  }}
                >
                  <Routes>
                    {/* <Route path='cotas' element={<RequireAuth levelReq={0}><Cotas /></RequireAuth> } />  */}
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="checkin" element={<CheckIn />} />
                    <Route path="eventos" element={<EventoPage />} />
                    <Route path="pessoa" element={<PessoaPage />} />
                    <Route path="cad_comum" element={<ComumPage />} />
                    <Route path="cad_cargo" element={<CargoPage />} />
                  </Routes>
                </Col>
                <Row
                  style={{
                    position: "fixed",
                    bottom: 30,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col span={24}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Col
                        onClick={() => navigate("dashboard")}
                        className={styles.col_item_menu_start}
                      >
                        <DesktopOutlined className={styles.icon_item_menu} />
                        <span className={styles.item_menu}>Dashboard</span>
                      </Col>
                      {auth.perfil.id == "1" || auth.perfil.id == "2" ? (
                        <>
                          <Col
                            onClick={() => {
                              navigate("checkin");
                            }}
                            className={styles.col_item_menu}
                          >
                            <ScanOutlined className={styles.icon_item_menu} />
                            <span className={styles.item_menu}>CheckIn</span>
                          </Col>
                          <Col
                            onClick={() => navigate("eventos")}
                            className={styles.col_item_menu}
                          >
                            <CoffeeOutlined className={styles.icon_item_menu} />
                            <span className={styles.item_menu}>Eventos</span>
                          </Col>
                        </>
                      ) : null}
                      <Col
                        onClick={() => navigate("pessoa")}
                        className={styles.col_item_menu}
                      >
                        <IdcardFilled className={styles.icon_item_menu} />
                        <span className={styles.item_menu}>Cadastro</span>
                      </Col>
                      {/* <Col onClick={() => navigate('meusPedidos')} className={styles.col_item_menu}>
                                            <SettingOutlined className={styles.icon_item_menu}  />
                                            <span className={styles.item_menu}>Configurações</span>                                    
                                        </Col> */}
                    </Row>
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
          <Footer
            style={{
              position: "fixed",
              bottom: -15,
              right: 5,
              textAlign: "right",
              backgroundColor: "transparent",
              color: "#ffffffc7",
              fontSize: 10,
              width: "100vw",
            }}
          >
            CheckIn Setor 3 ©2021 by RDCA-TI - Version:{" "}
            {process.env.REACT_APP_VERSION}
          </Footer>
        </div>

        <Drawer
          title={`${auth?.perfil.nome}`}
          placement="left"
          onClose={onClose}
          open={open}
          footer={
            <Button
              shape="circle"
              icon={
                <PoweroffOutlined style={{ color: "red" }} title={"Sair"} />
              }
              onClick={auth?.signout}
            >
              Sair
            </Button>
          }
        >
          <Row
            style={{
              fontSize: 12,
              color: "#00000080",
              paddingLeft: 15,
            }}
          >
            Pessoa Logada&nbsp;
          </Row>
          <Row>
            <Title style={{ fontSize: 18 }}>
              {auth!.user!.nome}
              <Tooltip title={"Definir nova senha"}>
                <Button
                  style={{ color: "#000000", fontWeight: 600 }}
                  type="link"
                  onClick={() => setOpenNovaSenha(true)}
                  icon={<ContactsOutlined />}
                ></Button>
              </Tooltip>
              <Tooltip title={"Exibir QRCode"}>
                <Button
                  style={{ color: "#000000", fontWeight: 600 }}
                  type="link"
                  onClick={() => carregarDadosQRCode()}
                  icon={<QrcodeOutlined />}
                ></Button>
              </Tooltip>
            </Title>
          </Row>

          <Row
            style={{
              fontSize: 12,
              color: "#00000080",
              paddingLeft: 15,
            }}
          >
            Comum&nbsp;
            {auth.user?.isadm ?? (
              <Popover title={"Clique no nome abaixo para trocar de comum."}>
                <QuestionCircleFilled />
              </Popover>
            )}
          </Row>

          <Row>
            <Title style={{ fontSize: 18 }}>
              {auth.user?.isadm ? (
                <Button
                  style={{ color: "#000000", fontWeight: 600 }}
                  type="link"
                  onClick={() => openModalSelectComum()}
                >
                  {auth.comum_sel?.nome
                    ? auth.comum_sel?.nome
                    : "Selecione uma comum!"}
                </Button>
              ) : (
                auth.comum_sel?.nome
              )}
            </Title>
          </Row>
          {auth.user?.isadm ? (
            <List>
              <List.Item
                key={"cad_comuns"}
                actions={[
                  <Button
                    key={"cad_comum"}
                    onClick={() => {
                      navigate("cad_comum");
                      onClose();
                    }}
                  >
                    <ArrowRightOutlined />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<BankOutlined />}
                  title={"Cadastro de Comuns"}
                  description={"Cadastro de todas as comuns do setor."}
                ></List.Item.Meta>
              </List.Item>
              <List.Item
                key={"cad_cargos"}
                actions={[
                  <Button
                    key={"cad_cargo"}
                    onClick={() => {
                      navigate("cad_cargo");
                      onClose();
                    }}
                  >
                    <ArrowRightOutlined />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<ToolOutlined />}
                  title={"Cadastro de Cargos / Funções"}
                  description={
                    "Cadastro de todos os cargos e funções do setor."
                  }
                ></List.Item.Meta>
              </List.Item>
            </List>
          ) : null}
        </Drawer>
        <Modal
          title={"Escolha uma comum"}
          open={openModalComum}
          footer
          width={600}
          onCancel={onCloseSelectComum}
        >
          <List>
            {comuns.map((item: any, index) => {
              return (
                <List.Item key={index} className="star">
                  <Button
                    type="link"
                    onClick={() => setComumClick(item)}
                    style={{ color: "#000000" }}
                  >
                    <StarFilled />
                    {item.nome}
                  </Button>
                </List.Item>
              );
            })}
          </List>
        </Modal>
        <Modal
          title="Alterar sua senha"
          open={openNovaSenha}
          onCancel={() => setOpenNovaSenha(false)}
          onOk={() => setNovaSenha()}
        >
          <Form form={form}>
            <Form.Item name={"senha_atual"} label={"Senha Atual"}>
              <Input.Password />
            </Form.Item>
            <Form.Item name={"nova_senha"} label={"Nova Senha"}>
              <Input.Password />
            </Form.Item>
            {/*<Form.Item name={"confirma_senha"} label={"Confirme a Senha"}>
              <Input.Password />
          </Form.Item>*/}
          </Form>
        </Modal>
      </Spin>
      {cracha != undefined ? (
        <Modal
          title={"Gerador QrCode"}
          open={openModalQrCode}
          onOk={onOkModalQrCode}
          onCancel={onCancelModalQrCode}
          footer
        >
          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col style={{ padding: 2.5 }}>
              <div id={"cracha_" + cracha!.id}>
                <Card className={styles.card}>
                  <Row className={styles.card_row}>
                    <Row
                      style={{
                        fontSize: 11,
                        textAlign: "center",
                        height: 28,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 800,
                        marginTop: -10,
                      }}
                    >
                      {cracha!.nome}
                    </Row>
                    <Row
                      style={{
                        fontSize: 10,
                        textAlign: "center",
                        height: 20,
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      {cracha!.comum}
                    </Row>
                    {/*<Divider style={{ marginTop: 5 }}>
                      <QrcodeOutlined />
                    </Divider>*/}
                    <span style={{ fontSize: 10, marginTop: 2.5 }}>
                      Apresente ao Leitor
                    </span>
                    <QRCode
                      //errorLevel={'L' as QRCodeProps['errorLevel']}
                      size={size}
                      iconSize={size / 4}
                      value={
                        '{"codigo": "' +
                        cracha!.id +
                        '", "comum": "' +
                        cracha!.comum +
                        '", "id_comum": "' +
                        cracha!.comum_id +
                        '", "cargo": "' +
                        cracha!.cargos!.map((cargo) => cargo.id) +
                        '", "cargos": "' +
                        cracha!.cargos!.map((cargo) => cargo.id) +
                        '"}'
                      }
                      //icon={icon}
                    />
                    <span
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        lineHeight: 1,
                        fontWeight: 500,
                        marginTop: 5,
                        marginBottom: 15,
                      }}
                    >
                      {cracha!.cargos?.map((cargo) => cargo.nome)}
                    </span>
                    <span style={{ fontSize: 10, marginTop: -20 }}>
                      {"CheckIn - Setor 03"}
                    </span>
                    <span style={{ fontSize: 10, marginTop: 5 }}>
                      Validade: {cracha!.validade}
                    </span>
                  </Row>
                </Card>
              </div>
              {/* <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 5,
                }}
              >
                <Space wrap>
                  <Button
                    onClick={() =>
                      downloadQRCode(
                        "cracha_" + cracha!.key,
                        cracha!.nome,
                        cracha!.comum,
                        cracha!.cargos
                      )
                    }
                    type="primary"
                  >
                    <DownloadOutlined />
                  </Button>                  
                </Space>
              </Row> */}
            </Col>
          </Row>
        </Modal>
      ) : null}
    </>
  );
};
