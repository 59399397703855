import {
  CheckCircleOutlined,
  CodeOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditFilled,
  OrderedListOutlined,
  PlusOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  FloatButton,
  Form,
  Input,
  message,
  Modal,
  notification,
  QRCode,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import styles from "./styles.module.css";
import * as htmlToImage from "html-to-image";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { CardFI } from "../../components/CardFI";
import { CheckIn } from "../CheckIn";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { MaskedInput } from "antd-mask-input";

interface RecordType {
  key: string;
  title: string;
}

interface DataType {
  key: string;
  nome: string;
  tipo: string;
  comum: string;
  comum_id: string;
  cargos: any;
  validade: string;
  ativo: boolean;
}

export const PessoaPage = () => {
  const api = useApi();
  const auth = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [openModalQrCode, setOpenModalQrCode] = useState(false);
  const [comuns, setComuns] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<DataType[]>();
  const [cracha, setCracha] = useState<DataType>();
  const [size, setSize] = useState(150);
  const [modalCarteirinhas, setModalCarteirinhas] = useState(false);
  const [onLoad, setOnLoad] = useState(false);
  const [msgLoad, setMsgLoad] = useState("");
  const [comumSel, setComumSel] = useState<number | null>(null);
  const [comunsIrmaos, setComunsIrmaos] = useState([]);
  const [contaColuna, setContaColuna] = useState<number>(0);
  const [contaLinha, setContaLinha] = useState<number>(0);
  const [cpf, setCpf] = useState("");
  const [telefone, setTelefone] = useState("");

  //Lista
  const columns: ColumnsType<DataType> = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      render: (_, record) => {
        return (
          <>
            <Row style={{ fontSize: 10 }}>{record.tipo}:&nbsp;</Row>
            <Row style={{ fontSize: 12 }}>
              {record.ativo ? (
                <a onClick={() => onEdit(record.key)}>
                  <strong>{record.nome}</strong>
                </a>
              ) : (
                <span>
                  <strong>{record.nome}</strong>
                </span>
              )}
            </Row>
            <Row style={{ fontSize: 10, color: "#414141" }}>
              Cargo(s):
              {record.cargos.map((cargo: any, index: number = 0) => (
                <>
                  {index > 0
                    ? ","
                    : () => {
                        index = 1;
                      }}
                  &nbsp;
                  {cargo.nome}
                </>
              ))}
            </Row>
          </>
        );
      },
    },
    /*{
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      width: "10%",
    },*/
    /*{
      title: "Comum",
      dataIndex: "comum",
      key: "comum",
    },*/
    // {
    //   title: "Cargo / Função",
    //   dataIndex: "cargo",
    //   key: "cargo",
    //   width: "30%",
    // },
    // {
    //   title: "Ações",
    //   key: "action",
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Space size="middle">
    //           <Button
    //             type="link"
    //             size="small"
    //             onClick={() => onEdit(record.key)}
    //             icon={<EditFilled />}
    //             disabled={!record.ativo}
    //           />
    //           <Button
    //             type="link"
    //             size="small"
    //             onClick={() => createQrCode(record.key)}
    //             icon={<QrcodeOutlined />}
    //             disabled={!record.ativo}
    //           />
    //           <Button
    //             type="link"
    //             size="small"
    //             onClick={() =>
    //               deletePessoa(Number(record.key), Number(record.comum_id))
    //             }
    //             icon={
    //               <DeleteOutlined
    //                 style={{ color: record.ativo ? "red" : "gray" }}
    //               />
    //             }
    //             disabled={!record.ativo}
    //           />
    //         </Space>
    //       </>
    //     );
    //   },
    //   width: "20%",
    // },
  ];

  const deletePessoa = async (id: number, comum_id: number) => {
    await api.deletePessoa(id).then((res) => {
      notification.info({
        message: "Resposta do servidor.",
        description: res.message,
      });
      getPessoas(parseInt(localStorage.getItem("localId")!));
      form.resetFields();
      form.setFieldValue("comum_id", localStorage.getItem("localId"));
      form.setFieldValue("comum_nome", localStorage.getItem("localNome"));
      setOpenModal(false);
    });
  };

  const createQrCode = async (id: string) => {
    setOnLoad(true);
    setMsgLoad("Criando o QRCode, aguarde...");
    await api.getPessoa(id).then((pessoa) => {
      pessoa.key = pessoa.id;
      setCracha(pessoa);
      setOpenModalQrCode(true);
    });
    setOnLoad(false);
    setMsgLoad("");
  };

  const onEdit = async (pessoa: string) => {
    setOnLoad(true);
    setMsgLoad("Alterando dados do cadastro, aguarde...");
    await api.getPessoa(pessoa).then((pessoa) => {
      form.setFieldValue("id", pessoa.id);
      form.setFieldValue("nome", pessoa.nome);
      form.setFieldValue("comum_id", pessoa.comum_id);
      form.setFieldValue("tipo", pessoa.tipo);
      form.setFieldValue("telefone", pessoa.telefone);
      form.setFieldValue("email", pessoa.email);
      form.setFieldValue("cpf", pessoa.cpf);
      setTelefone(pessoa.telefone);
      setCpf(pessoa.cpf);
      let cargos: Number[] = [];
      pessoa.cargos.map((cargo: any) => {
        cargos.push(cargo.id);
      });
      form.setFieldValue("cargo_id", cargos);
      setOpenModal(true);
    });
    setOnLoad(false);
    setMsgLoad("");
  };

  const onHandleOk = () => {
    setOpenModal(true);
  };

  const onHandleCancel = () => {
    form.resetFields();
    form.setFieldValue("comum_id", localStorage.getItem("localId"));
    form.setFieldValue("comum_nome", localStorage.getItem("localNome"));
    setOpenModal(false);
  };

  const getComuns = async () => {
    setOnLoad(true);
    setMsgLoad("Buscando comuns, aguarde...");
    await api.getComuns().then((res) => {
      if (res) {
        const option: any = [];
        res.forEach((item: any) => {
          option.push({
            value: item.id,
            label: item.codigo + " - " + item.nome,
          });
        });
        setComuns(option);
      } else {
        message.error("Não há comuns cadastrados");
        setComuns([]);
      }
    });
    setOnLoad(false);
    setMsgLoad("");
  };

  const getCargos = async () => {
    setOnLoad(true);
    setMsgLoad("Buscando cargos, aguarde...");
    await api.getCargos().then((res) => {
      if (res) {
        const option: any = [];
        res.forEach((item: any) => {
          option.push({
            value: item.id,
            label: item.nome,
          });
        });
        setCargos(option);
      } else {
        message.error("Não há cargos cadastrados");
        setCargos([]);
      }
    });
    setOnLoad(false);
    setMsgLoad("");
  };

  const getPessoas = async (e: number) => {
    setOnLoad(true);
    setMsgLoad("Buscando irmãos, aguarde...");
    setComumSel(e);
    await api.getPessoas(e).then((res) => {
      const newData: any = [];
      res.forEach((item: any) => {
        const m: DataType = {
          key: item.id,
          nome: item.nome,
          comum: item.comum,
          comum_id: item.comum_id,
          cargos: item.cargos,
          tipo: item.tipo,
          validade: item.validade,
          ativo: item.ativo,
        };
        newData.push(m);
      });
      setDataSource(newData);
    });
    setOnLoad(false);
    setMsgLoad("");
  };

  const postPessoa = async (values: any) => {
    setOnLoad(true);
    setMsgLoad(
      `Registrando ${form.getFieldValue("tipo")} ${form.getFieldValue(
        "nome"
      )}, aguarde...`
    );
    await api.postPessoa(values).then((res) => {
      message.success(res.message);
      form.resetFields();
      form.setFieldValue("comum_id", localStorage.getItem("localId"));
      form.setFieldValue("comum_nome", localStorage.getItem("localNome"));
      setOpenModal(false);
      getPessoas(values.comum_id);
    });
    setOnLoad(false);
    setMsgLoad("");
  };

  const onOkModalQrCode = () => {
    setOpenModalQrCode(true);
  };

  const onCancelModalQrCode = () => {
    setOpenModalQrCode(false);
  };

  const downloadQRCode = async (
    cracha: string,
    nome: string,
    comum: string,
    cargo: string
  ) => {
    if (cracha != null) {
      const imagem = document.getElementById(cracha) as HTMLElement;
      await htmlToImage.toPng(imagem).then(function (dataUrl: string) {
        const link = document.createElement("a");
        link.download = comum + "_" + cargo + "_" + nome + ".png";
        link.href = dataUrl;
        link.click();
      });
      /*await htmlToImage
        .toJpeg(imagem, { quality: 1.0 })
        .then((dataUrl: string) => {
          const link = document.createElement("a");
          link.download = comum + "_" + cargo + "_" + nome + ".jpeg";
          link.href = dataUrl;
          link.click();
        });*/
    }
  };

  const onGeraPdfCracha = async (comum: number | null) => {
    setOnLoad(true);
    setMsgLoad("Buscando dados para criar os crachás, aguarde...");
    await api.getCrachasPDF(comum).then((res) => {
      setComunsIrmaos(res);
      console.log(comunsIrmaos);
      setModalCarteirinhas(true);
    });
    setOnLoad(false);
    setMsgLoad("");
  };

  const onCloseModalPdf = () => {
    setModalCarteirinhas(false);
  };

  useEffect(() => {
    if (auth.perfil.id == "1" || auth.perfil.id == "2") getComuns();
    getCargos();
    //localStorage.getItem("localId") != undefined ??
    getPessoas(parseInt(localStorage.getItem("localId")!));
  }, []);

  return (
    <Spin
      spinning={onLoad}
      tip={msgLoad}
      style={{ color: "#000", zIndex: 999 }}
    >
      <CardFI
        id="cadIrmaos"
        title={"Cadastro de irmãos"}
        size="80vw"
        display="block"
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "55vh",
            padding: 10,
          }}
        >
          <Row
            style={{
              display: "flex",
              width: "100%",
              marginTop: -20,
            }}
          >
            {auth.perfil.id == "1" || auth.perfil.id == "2" ? (
              <Col span={24}>
                <span style={{ color: "#ffffff", fontWeight: 600 }}>
                  Escolha uma Comum
                </span>
                <Select
                  options={comuns}
                  onChange={(e) => getPessoas(e)}
                  style={{ width: "100%" }}
                />
              </Col>
            ) : (
              <span
                style={{ paddingBottom: 10, color: "#ffffff", fontWeight: 800 }}
              >
                {localStorage.getItem("localNome")}
              </span>
            )}
            {/* </Col> */}
            {/*<Col style={{ paddingRight: 5, marginTop: 23 }}>
              <Button
                icon={<OrderedListOutlined />}
                onClick={() => {
                  onGeraPdfCracha(comumSel);
                }}
              >
                Gerar identificação desta comum
              </Button>
            </Col>
            <Col style={{ marginTop: 23 }}>
              <Button
                icon={<OrderedListOutlined />}
                onClick={() => {
                  onGeraPdfCracha(null);
                }}
              >
                Gerar todas identificações setor 3
              </Button>
            </Col>*/}
          </Row>
          <Table
            footer={() => <>Total de Irmãos: {dataSource?.length}</>}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{ y: "30vh" }}
            style={{ width: "100%" }}
            size="small"
          />
          <Divider style={{ marginTop: -5 }} />
          <Row style={{ marginTop: -5 }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                form.resetFields();
                onHandleOk();
              }}
            >
              Cadastrar Irmã(o)
            </Button>
          </Row>
        </Row>
      </CardFI>
      <Modal
        title={"Cadastro de Pessoas"}
        open={openModal}
        onOk={onHandleOk}
        onCancel={onHandleCancel}
        footer
      >
        <Form form={form} onFinish={postPessoa}>
          <Form.Item name={"id"} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name={"nome"}
            label={"Nome"}
            rules={[{ required: true, message: "Nome é obrigatório." }]}
          >
            <Input
              onInput={(e) =>
                ((e.target as HTMLInputElement).value = (
                  e.target as HTMLInputElement
                ).value.toUpperCase())
              }
            />
          </Form.Item>
          <Form.Item
            name={"tipo"}
            label={"Tipo"}
            rules={[{ required: true, message: "Tipo é obrigatório." }]}
          >
            <Select
              options={[
                { value: "Irmão", label: "Irmão" },
                { value: "Irmã", label: "Irmã" },
              ]}
            />
          </Form.Item>
          {auth.perfil.id == "1" || auth.perfil.id == "2" ? (
            <Form.Item
              name={"comum_id"}
              label="Comum"
              rules={[{ required: true, message: "A comum é obrigatória." }]}
            >
              <Select options={comuns} />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                hidden
                name={"comum_id"}
                initialValue={localStorage.getItem("localId")}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={"comum_nome"}
                initialValue={localStorage.getItem("localNome")}
                label={"Comum"}
              >
                <Input readOnly disabled />
              </Form.Item>
            </>
          )}
          <Form.Item
            name={"cargo_id"}
            label={"Cargo / Função"}
            rules={[{ required: true, message: "Cargo é obrigatório." }]}
          >
            <Select mode="multiple" allowClear options={cargos} />
          </Form.Item>
          <Form.Item
            name={"cpf"}
            label="CPF"
            rules={[{ required: true, message: "CPF é obrigatório." }]}
            initialValue={cpf}
          >
            <MaskedInput
              mask={"000.000.000-00"}
              placeholder="000.000.000-00"
              value={cpf}
            />
          </Form.Item>
          <Form.Item
            name={"email"}
            label={"e-mail"}
            rules={[{ required: true, message: "E-mail é obrigatório." }]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item name={"telefone"} label="Celular" initialValue={telefone}>
            <MaskedInput
              mask={"(00) 00000-0000"}
              placeholder="(00) 00000-0000"
              required={true}
            />
          </Form.Item>
          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "center" }}>
              <Button
                htmlType="submit"
                icon={<CheckCircleOutlined style={{ color: "green" }} />}
              >
                Gravar
              </Button>
              <Button
                onClick={() => createQrCode(form.getFieldValue("id"))}
                icon={<QrcodeOutlined />}
              >
                Gerar QRCode
              </Button>
              <Button
                onClick={() =>
                  deletePessoa(Number(form.getFieldValue("id")), 0)
                }
                icon={
                  <DeleteOutlined
                    // style={{ color: record.ativo ? "red" : "gray" }}
                    style={{ color: "red" }}
                  />
                }
                // disabled={!record.ativo}
              >
                Desabilitar Cadastro
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={"Gerar Todas Carteirinhas"}
        open={modalCarteirinhas}
        onCancel={onCloseModalPdf}
        onOk={onCloseModalPdf}
        footer
      >
        {comunsIrmaos != null ? (
          <>
            <Document>
              {comunsIrmaos.map((membro: any) => {
                return (
                  <>
                    <Row>
                      <div id={"cracha_" + membro!.id}>
                        <Card className={styles.card}>
                          <Row className={styles.card_row}>
                            <Row
                              style={{
                                fontSize: 11,
                                textAlign: "center",
                                height: 28,
                                display: "flex",
                                alignItems: "center",
                                fontWeight: 800,
                                marginTop: -10,
                              }}
                            >
                              {membro!.nome}
                            </Row>
                            <Row
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                marginTop: 10,
                              }}
                            >
                              {membro!.comum}
                            </Row>
                            <Divider style={{ marginTop: 5 }}>
                              <QrcodeOutlined />
                            </Divider>
                            <span style={{ fontSize: 10, marginTop: -18 }}>
                              Apresente ao Leitor
                            </span>
                            <QRCode
                              //errorLevel={'L' as QRCodeProps['errorLevel']}
                              size={size}
                              iconSize={size / 4}
                              value={
                                '{"codigo": "' +
                                membro!.id +
                                '", "comum": "' +
                                membro!.comum +
                                '", "id_comum": "' +
                                membro!.comum_id +
                                '", "cargo": "' +
                                membro!.cargo +
                                '", "cargo_id": "' +
                                membro!.cargo_id +
                                '"}'
                              }
                              //icon={icon}
                            />
                            <span
                              style={{
                                fontSize: 14,
                                textAlign: "center",
                                lineHeight: 1,
                                fontWeight: 500,
                                marginTop: 5,
                                marginBottom: 15,
                              }}
                            >
                              {membro!.cargo}
                            </span>
                            <span style={{ fontSize: 10, marginTop: -10 }}>
                              {"CheckIn - Setor 03"}
                            </span>
                          </Row>
                        </Card>
                      </div>
                    </Row>
                    {membro.next()}
                    <Row>
                      <div id={"cracha_" + membro!.id}>
                        <Card className={styles.card}>
                          <Row className={styles.card_row}>
                            <Row
                              style={{
                                fontSize: 11,
                                textAlign: "center",
                                height: 28,
                                display: "flex",
                                alignItems: "center",
                                fontWeight: 800,
                                marginTop: -10,
                              }}
                            >
                              {membro!.nome}
                            </Row>
                            <Row
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                marginTop: 10,
                              }}
                            >
                              {membro!.comum}
                            </Row>
                            {/*<Divider style={{ marginTop: 5 }}>
                              <QrcodeOutlined />
                            </Divider>*/}
                            <span style={{ fontSize: 10, marginTop: -18 }}>
                              Apresente ao Leitor
                            </span>
                            <QRCode
                              //errorLevel={'L' as QRCodeProps['errorLevel']}
                              size={size}
                              iconSize={size / 4}
                              value={
                                '{"codigo": "' +
                                membro!.id +
                                '", "comum": "' +
                                membro!.comum +
                                '", "id_comum": "' +
                                membro!.comum_id +
                                '", "cargo": "' +
                                membro!.cargo +
                                '", "cargo_id": "' +
                                membro!.cargo_id +
                                '"}'
                              }
                              //icon={icon}
                            />
                            <span
                              style={{
                                fontSize: 14,
                                textAlign: "center",
                                lineHeight: 1,
                                fontWeight: 500,
                                marginTop: 5,
                                marginBottom: 15,
                              }}
                            >
                              {membro!.cargo}
                            </span>
                            <span style={{ fontSize: 10, marginTop: -10 }}>
                              {"CheckIn - Setor 03"}
                            </span>
                          </Row>
                        </Card>
                      </div>
                    </Row>
                    <Row>
                      <div id={"cracha_" + membro!.id}>
                        <Card className={styles.card}>
                          <Row className={styles.card_row}>
                            <Row
                              style={{
                                fontSize: 11,
                                textAlign: "center",
                                height: 28,
                                display: "flex",
                                alignItems: "center",
                                fontWeight: 800,
                                marginTop: -10,
                              }}
                            >
                              {membro!.nome}
                            </Row>
                            <Row
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                marginTop: 10,
                              }}
                            >
                              {membro!.comum}
                            </Row>
                            <Divider style={{ marginTop: 5 }}>
                              <QrcodeOutlined />
                            </Divider>
                            <span style={{ fontSize: 10, marginTop: -18 }}>
                              Apresente ao Leitor
                            </span>
                            <QRCode
                              //errorLevel={'L' as QRCodeProps['errorLevel']}
                              size={size}
                              iconSize={size / 4}
                              value={
                                '{"codigo": "' +
                                membro!.id +
                                '", "comum": "' +
                                membro!.comum +
                                '", "id_comum": "' +
                                membro!.comum_id +
                                '", "cargo": "' +
                                membro!.cargo +
                                '", "cargo_id": "' +
                                membro!.cargo_id +
                                '"}'
                              }
                              //icon={icon}
                            />
                            <span
                              style={{
                                fontSize: 14,
                                textAlign: "center",
                                lineHeight: 1,
                                fontWeight: 500,
                                marginTop: 5,
                                marginBottom: 15,
                              }}
                            >
                              {membro!.cargo}
                            </span>
                            <span style={{ fontSize: 10, marginTop: -10 }}>
                              {"CheckIn - Setor 03"}
                            </span>
                          </Row>
                        </Card>
                      </div>
                    </Row>
                  </>
                );
              })}
            </Document>
          </>
        ) : (
          <Row style={{ color: "#000" }}>Sem Registros</Row>
        )}
      </Modal>
      {cracha != undefined ? (
        <Modal
          title={"Gerador QrCode"}
          open={openModalQrCode}
          onOk={onOkModalQrCode}
          onCancel={onCancelModalQrCode}
          footer
        >
          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col style={{ padding: 2.5 }}>
              <div id={"cracha_" + cracha!.key}>
                <Card className={styles.card}>
                  <Row className={styles.card_row}>
                    <Row
                      style={{
                        fontSize: 11,
                        textAlign: "center",
                        height: 28,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 800,
                        marginTop: -10,
                      }}
                    >
                      {cracha!.nome}
                    </Row>
                    <Row
                      style={{
                        fontSize: 10,
                        textAlign: "center",
                        height: 20,
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      {cracha!.comum}
                    </Row>
                    {/*<Divider style={{ marginTop: 5 }}>
                      <QrcodeOutlined />
                    </Divider>*/}
                    <span style={{ fontSize: 10, marginTop: 2.5 }}>
                      Apresente ao Leitor
                    </span>
                    <QRCode
                      //errorLevel={'L' as QRCodeProps['errorLevel']}
                      size={size}
                      iconSize={size / 4}
                      value={
                        '{"codigo": "' +
                        cracha!.key +
                        '", "comum": "' +
                        cracha!.comum +
                        '", "id_comum": "' +
                        cracha!.comum_id +
                        '", "cargo": "' +
                        cracha!.cargos +
                        '", "cargo_id": "' +
                        cracha!.cargos +
                        '"}'
                      }
                      //icon={icon}
                    />
                    <span
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        lineHeight: 1,
                        fontWeight: 500,
                        marginTop: 5,
                        marginBottom: 15,
                      }}
                    >
                      {cracha!.cargos}
                    </span>
                    <span style={{ fontSize: 10, marginTop: -10 }}>
                      {"CheckIn - Setor 03"}
                    </span>
                    <span style={{ fontSize: 10, marginTop: 5 }}>
                      Validade: {cracha!.validade}
                    </span>
                  </Row>
                </Card>
              </div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 5,
                }}
              >
                <Space wrap>
                  <Button
                    onClick={() =>
                      downloadQRCode(
                        "cracha_" + cracha!.key,
                        cracha!.nome,
                        cracha!.comum,
                        cracha!.cargos
                      )
                    }
                    type="primary"
                  >
                    <DownloadOutlined />
                  </Button>
                  {/* <Button
                                    danger
                                    onClick={() => {
                                    deleteCracha(item.id);
                                    }}
                                >
                                    <DeleteOutlined />
                                </Button> */}
                </Space>
              </Row>
            </Col>
          </Row>
        </Modal>
      ) : null}
    </Spin>
  );
};
