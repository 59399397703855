import React, { useContext, useState } from "react";
import "./App.css";
import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { AuthContext } from "./contexts/Auth/AuthContext";
import { ThemeData } from "./types/ThemeData";
import { AuthProvider } from "./contexts/Auth/AuthProvider";
import { Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login";
import { RequireAuth } from "./contexts/Auth/RequireAuth";
import Sistema from "./pages/Sistema";
import { ComumSel } from "./pages/ComumSel";
import { HomePage } from "./pages/Home";

const defaultData: ThemeData = {
  borderRadius: 10,
  colorPrimary: "#0B0B3B",
};

function App() {
  const auth = useContext(AuthContext);

  const [data, setData] = useState<ThemeData>(defaultData);

  return (
    <ConfigProvider
      locale={ptBR}
      theme={{
        token: {
          colorPrimary: data.colorPrimary,
          borderRadius: data.borderRadius,
        },
      }}
    >
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="sistema/*"
            element={
              <RequireAuth levelReq={4}>
                <HomePage />
              </RequireAuth>
            }
          />
          <Route
            path="comum_sel"
            element={
              <RequireAuth levelReq={4}>
                <ComumSel />
              </RequireAuth>
            }
          />
        </Routes>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
