import {
  EyeInvisibleFilled,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Row,
  Space,
  Spin,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { Footer } from "antd/lib/layout/layout";
import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { CardFI } from "../../components/CardFI";
import { UserType } from "../../types/UserType";
import styles from "./styles.module.css";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApiAuthentication } from "../../hooks/useApiAuthentication";

export const Login = () => {
  const auth = useContext(AuthContext);
  const apiAuth = useApiAuthentication();
  const navigate = useNavigate();
  const [formLogin] = Form.useForm();
  const [loadOpen, setLoadOpen] = useState(false);
  const [msgLoad, setMsgLoad] = useState("");

  const signup = async (values: { cpf?: string; password?: string }) => {
    setMsgLoad("Autenticando usuário, aguarde...");
    setLoadOpen(true);
    if (values.cpf && values.password) {
      await auth!
        .signin(values.cpf!, values.password!)
        .then((us: any) => {
          if (us != null) {
            navigate("/sistema");
          } else {
            message.error("Usuário ou senha inválidos!");
          }
        })
        .catch((erro: any) => {
          notification.error({ message: erro.message });
        });
    } else {
      message.error("Usuário e senha são obrigatórios!");
    }
    setMsgLoad("");
    setLoadOpen(false);
  };

  const newPass = async () => {
    setMsgLoad("Requisitando nova senha.");
    setLoadOpen(true);
    if (formLogin.getFieldValue("cpf") != "") {
      await apiAuth.newPass(formLogin.getFieldValue("cpf")).then((res) => {
        message.warning({ content: res.message });
      });
    } else {
      message.error({ content: "Informe o cpf para criarmos uma nova senha!" });
    }
    setMsgLoad("");
    setLoadOpen(false);
  };

  useEffect(() => {
    /*if (auth.user != null) {
      //verificar se há fazenda selecionada
      if (auth.comum_sel) {
        navigate("/sistema");
      } else {
        if (auth.user.isadm == false) {
          navigate("/fazenda_sel");
        } else {
          navigate("/sistema");
        }
      }
    }*/
  }, []);

  return (
    <>
      <Spin
        spinning={loadOpen}
        tip={msgLoad}
        style={{ fontSize: 18, color: "#000" }}
        size="large"
      >
        <div className={styles.container}>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100vw",
              height: "100%",
            }}
            align={"middle"}
          >
            <Col
              style={{
                border: "1px solid #fff",
                borderRadius: 6,
                padding: 10,
                backgroundColor: "#00000068",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                  color: "#FFF",
                  fontWeight: "500",
                  fontSize: 20,
                  fontFamily: "Roboto",
                }}
              >
                CHECK-IN EVENTOS
              </Row>
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                  color: "#FFF",
                  fontWeight: "300",
                  fontSize: 14,
                  fontFamily: "Roboto",
                }}
              >
                AUTENTICAÇÃO
              </Row>
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <CardFI title="Login de Usuários" size="100%">
                                    <h1>teste</h1>
                                </CardFI> */}
                <Col span={20}>
                  <Form
                    name="formLogin"
                    form={formLogin}
                    onFinish={signup}
                    initialValues={{ remember: true }}
                  >
                    <Form.Item
                      className={styles.borderRadius}
                      name={"cpf"}
                      rules={[
                        { required: true, message: "Cpf é obrigatório!" },
                      ]}
                    >
                      <MaskedInput
                        prefix={<UserOutlined />}
                        placeholder={"CPF do usuário"}
                        id="cpf"
                        mask={"000.000.000-00"}
                        required={true}
                      />
                    </Form.Item>
                    <Form.Item
                      name={"password"}
                      rules={[
                        { required: true, message: "A senha é obrigatória!" },
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="Senha do usuário"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleFilled />
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{ width: "100%" }}
                        htmlType="submit"
                        type="primary"
                      >
                        Acessar
                      </Button>
                      {/* <Divider></Divider>
                                            Ou <NavLink to="/cadastrar/0" className={styles.planoContratarSel}>Cadastre-se agora!</NavLink> */}
                    </Form.Item>

                    <Row style={{ display: "flex", justifyContent: "center" }}>
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox>
                          <span style={{ color: "#fff" }}>Relembre-me</span>
                        </Checkbox>
                      </Form.Item>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 10,
                      }}
                    >
                      <Button
                        className="login-form-forgot"
                        onClick={(e) => newPass()}
                        style={{
                          color: "#ffffff",
                          backgroundColor: "transparent",
                        }}
                      >
                        Esqueci minha senha
                      </Button>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Footer
          style={{
            position: "fixed",
            bottom: -15,
            right: 10,
            backgroundColor: "transparent",
            color: "#fff",
            fontSize: 12,
          }}
        >
          CheckInST3 ©2021 - Version: {process.env.REACT_APP_VERSION}
        </Footer>
      </Spin>
    </>
  );
};
