import axios from  'axios';
import { getConfigLaravel as getConfig } from '../configs/sistemaConfig';

const api = axios.create({
    baseURL: window.location.hostname == "localhost" ? process.env.REACT_APP_API_DESV : process.env.REACT_APP_API_PROD,
    withCredentials: true,
    xsrfCookieName: "csrf-token",
    xsrfHeaderName: 'X-CSRF-TOKEN',
});

export const useApiComum = () => ({
    //FazendaBase
    saveComum: async (values: any) => {
        const response = await api.post('/comum/save', { values }, getConfig('priv'));
        return response.data;
    },
    listComuns: async (search: String) => {
        const response = await api.post('/comum/list', { search }, getConfig('priv'));
        return response.data;
    },
    listComumSetor: async (id: string) => {
        const response = await api.post('/comum/list/setor', { id }, getConfig('priv'));
        return response.data;
    },
    setComum: async (comum_id: number | null) => {
        const response = await api.post('/comum/set', { comum_id }, getConfig('priv'));
        return response.data;
    },
    searchUniverseComum: async (search: string) => {
        const response = await api.post('/comum/search/universe', { search }, getConfig('priv'));
        return response.data;
    },
});