import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AcessoIndevido } from "../../pages/AcessoIndevido";
import { ComumSel } from "../../pages/ComumSel";
import { Login } from "../../pages/Login";
import { Sistema } from "../../pages/Sistema";
import { AuthContext } from "./AuthContext";

export const RequireAuth = ({
  children,
  levelReq,
}: {
  children: JSX.Element;
  levelReq: number;
}) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  if (!auth.user) {
    ///auth.signout();
    return <Login />;
  }

  //validar pois ta jogando para fora
  if (!auth.user!.isadm) {
    console.log(auth?.comuns?.length);
    if (auth?.comuns?.length == 1) {
      auth.comum_sel = auth.comuns[0];
    } else if (!auth.comum_sel) {
      return <ComumSel />;
    }
  }

  if (auth!.user!.level > levelReq) {
    //pagina avisando que nao tem permissao.
    return <AcessoIndevido />;
  }

  return children;
};
